<template>
  <div class="register-select app_content">
    <div class="warp_body">

      <change-order-status-card :order-status="userInfo.orderStatus"></change-order-status-card>
    </div>

  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import API from "../api/User";
import req from "../request/request";
import router from "../router";
import ChangeOrderStatusCard from "@/components/common/changeOrderStatusCard";


export default {
  name: 'ChangeOrderStatus',
  components: {ChangeOrderStatusCard},
  setup() {
    onBeforeMount(() => {
      methodsMap.getInfo()
    });
    const data = reactive({
          userInfo: {}
        },
    )

    const methodsMap = {
      getInfo: () => {
        req.request(API.getDetailUserInfo, null, {method: 'GET'}).then(
            (res) => {
              data.userInfo = res.data.data
            }
        )
      },
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {


  }
}
</style>
